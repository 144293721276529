import 'rxjs/add/operator/filter';

import nxModule from 'nxModule';

const templateUrl = require('./roles-list.template.html');

nxModule.component('rolesList', {
  templateUrl,
  bindings: {
      // List of roles
      'roles': '=',
    },
  controller: function($scope, $location, rolesCache, http, notification, confirmation) {
    const that = this;
    const baseUrl = '/admin/security/roles/';
    that.header = [];

    that.openRole = (index) => {
        let redirectionUrl = baseUrl.concat(that.roles[index].id);
        $location.path(redirectionUrl);
    };

    that.addRole = () => {
      that.roles.push({dirty: true});
    };

    that.cancelAddRole = index => {
      that.roles.splice(index, 1);
    };

    that.isSaveDisabled = index => !that.roles[index].name;

    that.saveRole = index => {
      if(that.roles[index].name) {
        confirmation('Do you want to save the role?', () => {
          http.post('/management/roles', {
            name: that.roles[index].name,
            technical: false,
            cashierCounter: that.roles[index].cashierCounter,
            agent: !!that.roles[index].agent
          }, {nxLoaderText: 'Saving role'}).success(response => {
            rolesCache.evict();
            notification.show("Role has been saved.");
            that.roles[index].dirty = false;
            that.roles[index].id = response.id;
          })
        });
      }
    };

    that.deleteRole = index => {
      confirmation(`Do you want to remove role ${that.roles[index].name}?`, () => {
        http.doDelete(`/management/roles/${that.roles[index].id}`, {nxLoaderText: 'Removing role'})
          .success(ok => {
            rolesCache.evict();
            notification.show("Role has been removed.");
            that.roles.splice(index, 1);
          })
      });
    };
  }
});
