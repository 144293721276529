import nxModule from 'nxModule';
import {IController, ILocationService} from 'angular';
import {Column, SelectableListAPI} from '../../../common/selectable-list/selectable-list.component';
import templateUrl from './batch-clearing.template.html';
import './batch-clearing.style.less';
import {Columns} from '../../../common/dynamic-list/dynamic-list.component';
import _ from 'lodash';
import {BatchValidateInwardCheckOutput, ClearableCheckView, ClearInwardCheckOutput} from '../batch-clearing.types';

import {
  OutgoingCheckClearingError,
  OutgoingCheckClearingService
} from '../../check/outgoing/outgoing-check-clearing.service';
import {Branch} from "management/BranchTypes";
import {SystemDateService} from "components/service/system-date.service.types";
import {CommandService} from "shared/utils/command/command.types";
import {Confirmation} from "shared/common/confirmation.types";
import Popup from "shared/common/popup";
import {NxIFilterService} from "components/technical/angular-filters";
import {HttpService} from "shared/utils/httpService";
import Authentication from "shared/utils/authentication";
import {PageResult} from "tools/HttpTypes";
import {BranchService} from "components/service/branch.service";
import {Account} from "components/service/product.types";
import {OutgoingCheck} from "components/service/check.type";

class BatchClearing implements IController {
  // FILTERING
  private availableBranches: Branch[] = [];
  filter: { branchId: number, dateRange: { from: string, to: string } } | null = null;

  // CLEARABLE CHECKS LIST
  private selectedChecks: ClearableCheckView[] = [];
  private checkListAPI?: SelectableListAPI;
  readonly checkListColumns: Column<ClearableCheckView>[] = [
    new Column('Number', 'number'),
    new Column('MICR', 'micrNumber'),
    new Column('Product number', 'productNumber'),
    new Column('Status', 'status'),
    new Column('Amount', 'amountFormatted'),
    new Column('Registered on', 'registeredOnFormatted'),
  ];

  // VALIDATION RESULT
  private validCheckData: {
    checkNumber: string;
    micr: string;
    amount: string;
  }[] | null = null;

  private invalidCheckData: {
    checkNumber: string;
    micr: string;
    amount: string;
    clearingErrorsFormatted: string;
  }[] | null = null;

  readonly validCheckColumns: Columns = [
    { title: 'No' },
    { title: 'Number', field: 'checkNumber' },
    { title: 'MICR', field: 'micr' },
    { title: 'Amount', field: 'amount' }
  ];
  readonly invalidCheckColumns: Columns = [
    ...this.validCheckColumns,
    { title: 'Clearing errors', field: 'clearingErrorsFormatted' }
  ];

  constructor(private $location: ILocationService,
              private $filter: NxIFilterService,
              private http: HttpService,
              private branchService: BranchService,
              private systemDateService: SystemDateService,
              private authentication: Authentication,
              private command: CommandService,
              private popup: Popup,
              private confirmation: Confirmation,
              private outgoingCheckClearingService: OutgoingCheckClearingService) {}

  async $onInit(): Promise<void> {
    await this.initAvailableBranches();
    this.initDefaultFilters();
  }

  // FILTER-RELATED FUNCTIONS

  private async initAvailableBranches() {
    const branchIds: number[] = this.authentication.context.branchIds;
    const branches = await this.branchService.toPromise();
    this.availableBranches = branches.filter(branch => branchIds.includes(branch.id));
  }

  private initDefaultFilters() {
    const defaultBranch: Branch = this.availableBranches.find(branch => branch.id === this.authentication.context.branchId)!;
    const systemDate: string = defaultBranch.systemDate;
    this.filter = {
      branchId: defaultBranch.id,
      dateRange: {
        from: systemDate,
        to: systemDate
      }
    };
  }

  onBranchChange(): void {
    if (!this.filter || !this.filter.branchId) return;
    const selectedBranch = this.availableBranches.find(branch => branch.id === this.filter?.branchId);
    if(!selectedBranch) {
      return;
    }
    const systemDate = selectedBranch.systemDate;
    this.filter.dateRange = { from: systemDate, to: systemDate };
  }

  onFilterBtnClicked(): void {
    this.checkListAPI?.reset();
  }

  // CHECK LIST FUNCTIONS

  onCheckListApiReady(checkListAPI: SelectableListAPI) {
    this.checkListAPI = checkListAPI;
  }

  async getChecksOnCurrentPage(pageSettings: { pageNo: number, pageSize: number }): Promise<PageResult<ClearableCheckView>> {
    const url = '/checks/outgoing';
    const params = this.getQueryParams(pageSettings);
    const checksPage = await this.http.get<PageResult<OutgoingCheck>>(url, { params }).toPromise();

    const productIds: number[] = checksPage.result.map(check => check.productId);
    let checkProducts: Account[] = [];
    if (productIds && productIds.length > 0) {
      checkProducts = await this.http.get<Account[]>(`/products/accounts?ids=${ productIds }`).toPromise();
    }

    const checksView: ClearableCheckView[] = checksPage.result.map(check => ({
      id: check.id,
      number: check.number,
      micrNumber: check.micrNumber,
      productNumber: checkProducts.find(product => product.id === check.productId)!.productNumber,
      status: this.$filter('prettyEnum')(check.status),
      amountFormatted: this.$filter('nxCurrency')(check.amount),
      registeredOnFormatted: this.$filter('prettyDate')(check.registeredOn)
    }));

    return {
      ...checksPage,
      result: checksView
    };
  }

  private getQueryParams(pageSettings: { pageNo: number, pageSize: number }) {
    return {
      pageSize: pageSettings.pageSize,
      pageNo: pageSettings.pageNo,
      branchId: this.filter?.branchId,
      registeredOnFrom: this.$filter('nxDate')(this.filter?.dateRange.from),
      registeredOnTo: this.$filter('nxDate')(this.filter?.dateRange.to),
      clearingGroup: 'INWARD',
      clearingPerformed: false,
      status: ['PENDING_CLEARING', 'CLEARING_ERROR']
    };
  }

  onCheckSelected(checks: ClearableCheckView[]): void {
    this.selectedChecks = checks;
  }

  isAnyCheckSelected(): boolean {
    return this.selectedChecks && this.selectedChecks.length > 0;
  }

  // ACTIONS (clear & validate)

  async onValidateClicked(): Promise<void> {
    // clear previous validation result in case there was any
    this.clearValidationResult();

    // save for later
    const selectedChecks: ClearableCheckView[] = _.cloneDeep(this.selectedChecks);

    const checkIds: number[] = this.selectedChecks.map(check => check.id);
    const { output: validationResponse, approvalRequired } = await this.command.execute<unknown, BatchValidateInwardCheckOutput>('BatchValidateInwardCheck', {
      checkIds,
      failFast: false
    }).toPromise();

    if (approvalRequired) return;

    this.showSummaryPopup(validationResponse);
    this.showValidationResults(validationResponse, selectedChecks);

    this.checkListAPI?.reload();
  }

  private showSummaryPopup(validationResponse: BatchValidateInwardCheckOutput) {
    const isAllValid: boolean = validationResponse.valid;
    const validCount: number = validationResponse.validChecks.length;
    const invalidCount: number = validationResponse.invalidChecks.length;

    let msg: string;
    if (isAllValid) {
      msg = 'All checks are valid.';
    } else {
      msg = `<p>Checks validated successfully: ${ validCount }</p><p>Checks with errors: ${ invalidCount }</p>`
    }

    this.popup({ header: 'Info', text: msg, renderHtml: true });
  }

  private showValidationResults(validationResponse: BatchValidateInwardCheckOutput, selectedChecks: ClearableCheckView[]) {
    this.invalidCheckData = validationResponse.invalidChecks.map(checkInvalid => {
      const clearableCheckView = selectedChecks.find(check => check.id === checkInvalid.checkId)!;
      return {
        checkNumber: clearableCheckView.number,
        micr: clearableCheckView.micrNumber,
        amount: clearableCheckView.amountFormatted,
        clearingErrorsFormatted: this.formatClearingErrors(checkInvalid.clearingErrors)
      };
    });
    this.validCheckData = validationResponse.validChecks.map(checkValid => {
      const clearableCheckView = selectedChecks.find(check => check.id === checkValid.checkId)!;
      return {
        checkNumber: clearableCheckView.number,
        micr: clearableCheckView.micrNumber,
        amount: clearableCheckView.amountFormatted
      }
    });
  }

  private formatClearingErrors(errors: OutgoingCheckClearingError[]): string {
    return errors.map(error => this.outgoingCheckClearingService.format(error)).join(', ');
  }

  private clearValidationResult(): void {
    this.validCheckData = null;
    this.invalidCheckData = null;
  }

  async onClearClicked(): Promise<void> {
    const checkIds = this.selectedChecks.map(check => check.id);

    const proceed = await this.confirmation(`Are you sure you want to clear ${ checkIds.length } checks?`);
    if (!proceed) {
      return;
    }

    const { output } = await this.command.execute<unknown, ClearInwardCheckOutput[]>('BatchClearInwardCheck', { checkIds }).toPromise();

    if (output) {
      const checksWithSystemError = output.filter(o => o.systemErrorMessage !== null).map(c => this.formatCheckError(c));
      if (!checksWithSystemError || checksWithSystemError.length === 0) {
        this.popup({
          header: 'Info',
          text: 'Clearing process is finished. To see the result, go to "Clearing results" tab.'
        });
      } else {
        const msg = `<p>${checksWithSystemError.length} checks failed to clear.</p><p>See errors below</p>` + checksWithSystemError.join(`<br/>`)
        this.popup({ header: 'Info', text: msg, renderHtml: true });
      }
    }

    this.clearValidationResult();
    this.checkListAPI?.reset();
  }

  private formatCheckError(check: ClearInwardCheckOutput): string {
    if (!check) {
      return '';
    }
    const checkView = this.selectedChecks.find(c => c.id === check.checkId);
    return `MICR: ${checkView?.micrNumber} Error: ${check.systemErrorMessage}`
  }

}

nxModule.component('batchClearing', {
  templateUrl: templateUrl,
  controller: BatchClearing
});
