import {ILocationService} from 'angular';
import DepositAccountTypeService from 'components/administration/deposit-account/common/deposit-account-type-service';
import {AgentTransaction} from 'components/agent-banking/agent.types';
import TransactionQuotaCache from 'components/agent-banking/service/transaction-quota.cache';
import {AccountType} from 'components/service/account.types';
import {BranchService} from 'components/service/branch.service';
import {CustomerCache} from 'components/service/customer.cache.types';
import FeeDefinitionCache from 'components/service/fee-definition.cache';
import _ from 'lodash';
import {Branch} from 'management/BranchTypes';
import nxModule from 'nxModule';
import {NxRouteService} from 'routes/NxRouteService';
import {Confirmation} from 'shared/common/confirmation.types';
import {CommandService} from 'shared/utils/command/command.types';
import Notification from 'shared/utils/notification';
import {setBreadcrumbs} from 'shared/utils/set-breadcrumbs';

import templateUrl from './deposit-account-details.template.html';
import {NEW_DEPOSIT_ACCOUNT_TYPE} from './deposit-account.types';

class DepositAccountDetails {

  branches!: Branch[];
  sectionType: string = 'general';
  productId!: number;
  depositAccountType!: AccountType;
  glMappingComponent: unknown;

  constructor(private depositAccountTypeService: DepositAccountTypeService,
              private $route: NxRouteService,
              private $location: ILocationService,
              private breadcrumbs: unknown,
              private notification: Notification,
              private confirmation: Confirmation,
              private command: CommandService,
              private feeDefinitionsCache: FeeDefinitionCache,
              private branchService: BranchService,
              private transactionQuotaCache: TransactionQuotaCache,
              private customerCache: CustomerCache) {
  }

  async $onInit(): Promise<void> {

    const quotaPromise = isNaN(this.productId) ? Promise.resolve([]) :
      this.transactionQuotaCache.withParam({
        owner: 'ACCOUNT_TYPE',
        ownerId: Number(this.productId)
      }).toPromise();

    const [branches, accounts, feeDefs, quotas] = await Promise.all([
      this.branchService.toPromise(),
      this.depositAccountTypeService.toPromise(),
      this.feeDefinitionsCache.toPromise(),
      quotaPromise
    ]);

    this.branches = branches;

    const account = accounts.find(a => Number(a.id) === Number(this.productId));

    if (account) {
      this.depositAccountType = account;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.depositAccountType.transactionQuota = quotas;

      const ata = account.automaticTransferAgreement;
      if (ata) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.depositAccountType.replenishEnabled = (ata.replenishStrategy && ata.replenishStrategy !== 'NONE');
      }

      const productDef = account.productDefinition.id;
      const accountTypeFeeDefs = feeDefs
        .filter(fd => fd.productDefinitionId === productDef);

      this.extractFeeOverdraftStrategy(this.depositAccountType, accountTypeFeeDefs);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.depositAccountType.feeDefinitions = accountTypeFeeDefs
        .filter(fd => fd.feeClass === 'CUSTOM')
        .filter(fd => fd.enabled);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.depositAccountType.agentBalanceInquiryCharge = this.cloneFixedFee('AGENT_BALANCE_INQUIRY', accountTypeFeeDefs);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.depositAccountType.agentDepositCharge = this.cloneFixedFee('AGENT_DEPOSIT', accountTypeFeeDefs);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.depositAccountType.agentWithdrawalCharge = this.cloneFixedFee('AGENT_WITHDRAWAL', accountTypeFeeDefs);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.depositAccountType.agentBillCharge = this.cloneFixedFee('AGENT_BILL', accountTypeFeeDefs);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.depositAccountType.agentPrepaidCharge = this.cloneFixedFee('AGENT_PREPAID', accountTypeFeeDefs);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.depositAccountType.agentTopupCharge = this.cloneFixedFee('AGENT_TOP_UP', accountTypeFeeDefs);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.depositAccountType.transactionLimit = this.cloneFixedFee('TRANSACTION_LIMIT', accountTypeFeeDefs);

      // Set null returned from backend to empty array
      if (!this.depositAccountType.productDefinition.customerTypeConstraints) {
        this.depositAccountType.productDefinition.customerTypeConstraints = [];
      }
      if (!this.depositAccountType.productDefinition.allowedAgentTransactions) {
        this.depositAccountType.productDefinition.allowedAgentTransactions = [];
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.calculateAndApplyFeeType(this.depositAccountType.selfTransferCharge);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.calculateAndApplyFeeType(this.depositAccountType.internalTransferCharge);
    }

    setBreadcrumbs(
      this.breadcrumbs,
      'deposit-account-label',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.depositAccountType ? this.depositAccountType.productDefinition.productName : 'Create New'
    );


    if (!this.depositAccountType) {
      this.depositAccountType = _.cloneDeep(NEW_DEPOSIT_ACCOUNT_TYPE) as AccountType;
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  cloneFixedFee(feeClass, fees): any {
    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ..._.cloneDeep(fees.find(fd => fd.feeClass === feeClass)),
      feeType: 'FIXED'
    };
  }

  extractFeeOverdraftStrategy(accountProduct: unknown, feeDefinitions: unknown): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    accountProduct.maintainingFeeOverdraftStrategy = this.findFeeOverdraftStrategy(feeDefinitions, 'MAINTAINING');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    accountProduct.dormancyFeeOverdraftStrategy = this.findFeeOverdraftStrategy(feeDefinitions, 'DORMANCY');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    accountProduct.unfundedServiceChargeOverdraftStrategy = this.findFeeOverdraftStrategy(feeDefinitions, 'UNFUNDED_SERVICE_CHARGE');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    accountProduct.fixedPenaltyPerCheckChargeOverdraftStrategy = this.findFeeOverdraftStrategy(feeDefinitions, 'FIXED_PENALTY_PER_CHECK');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    accountProduct.variablePenaltyPerCheckChargeOverdraftStrategy = this.findFeeOverdraftStrategy(feeDefinitions, 'VARIABLE_PENALTY_PER_CHECK');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    accountProduct.presentingBankChargeOverdraftStrategy = this.findFeeOverdraftStrategy(feeDefinitions, 'PRESENTING_BANK_CHARGE_ON_RETURN');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    accountProduct.spoChargeOverdraftStrategy = this.findFeeOverdraftStrategy(feeDefinitions, 'BANK_CHARGE_ON_SPO');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    accountProduct.daudChargeOverdraftStrategy = this.findFeeOverdraftStrategy(feeDefinitions, 'BANK_CHARGE_ON_DAUD');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    accountProduct.spoPlacementChargeOverdraftStrategy = this.findFeeOverdraftStrategy(feeDefinitions, 'SPO_PLACEMENT_CHARGE');
  }


  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  findFeeOverdraftStrategy(fees, feeClass): unknown {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const feeDefinition = fees.find(fee => fee.feeClass === feeClass);
    if (feeDefinition && this.isFeeOverdraftStrategyValid(feeDefinition.overdraftStrategy)) {
      return feeDefinition.overdraftStrategy;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  isFeeOverdraftStrategyValid(feeOverdraftStrategy): boolean {
    return ['SKIP', 'DRAIN', 'OVERDRAFT'].includes(feeOverdraftStrategy);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  calculateAndApplyFeeType(charge): void {
    if (charge && !_.isNil(charge.fixedValue)) {
      charge.feeType = 'FIXED';
    } else if (charge && !_.isNil(charge.percentageValue)) {
      charge.feeType = 'PERCENTAGE';
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prepareChargeCommandInput(charge): unknown {
    return {
      fixedValue: charge.feeType === 'FIXED' ? charge.fixedValue : null,
      percentageValue: charge.feeType === 'PERCENTAGE' ? charge.percentageValue : null,
      overdraftStrategy: charge.overdraftStrategy
    };
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  createCommandInput(type): unknown {
    return {
      ...type,
      internalTransferCharge: this.prepareChargeCommandInput(type.internalTransferCharge),
      selfTransferCharge: this.prepareChargeCommandInput(type.selfTransferCharge),
      expired: type.productDefinition.expired,
      productName: type.productDefinition.productName,
      productTypeCode: type.productDefinition.productTypeCode,
      daysInYear: type.productDefinition.daysInYear,
      description: type.productDefinition.description,
      dormant: type.dormant,
      dormancyTerm: type.dormant ? type.dormantConfig.dormancyTerm : null,
      dormancyChargeTerm: type.dormant ? type.dormantConfig.chargeTerm : null,
      dormancyChargeAmount: type.dormant ? type.dormantConfig.chargeAmount : null,
      dormancyChargeBalanceThreshold: type.dormant ? type.dormantConfig.chargeBalanceThreshold : null,
      dormancyAllowInterest: type.dormant ? type.dormantConfig.allowInterest : null,
      interestCycleType: type.creditingScheduler.cycleType,
      interestExecutionInterval: type.creditingScheduler.interval,
      accrualCycleType: type.accrued ? type.accrualScheduler.cycleType : null,
      accrualExecutionInterval: type.accrued ? type.accrualScheduler.executionInterval : null,
      productAvailability: type.productDefinition.productAvailability,
      availableInBranchIds: type.productDefinition.availableInBranchIds,
      allowCreationByAgents: type.productDefinition.allowCreationByAgents,
      allowedAgentTransactions: type.productDefinition.allowedAgentTransactions,
      customerTypeConstraints: type.productDefinition.customerTypeConstraints,
      customFieldDefinitionIds: type.productDefinition.customFieldDefinitionIds
    };
  }

  async saveDeposit(): Promise<void> {
    const input = this.createCommandInput(this.depositAccountType);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.depositAccountType.id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = await this.command.execute('UpdateAccountType', input).toPromise();

      if (!response?.approvalRequired) {
        this.feeDefinitionsCache.refetch();
        this.depositAccountTypeService.refetch();
        this.transactionQuotaCache.withParam({
          owner: 'ACCOUNT_TYPE',
          ownerId: this.productId
        }).refetch();

        if (this.customerCache.loadedCustomerId) {
          await this.customerCache.depositAccounts(this.customerCache.loadedCustomerId).refetch();
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const response = await this.glMappingComponent.save();
        if (response) {
          this.redirectBack();
          this.notification.show('Success', 'Deposit Account updated successfully');
        }
      }

      if (response?.approvalRequired) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.glMappingComponent.save();
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = await this.command.execute('CreateAccountType', input).toPromise();
      if (!response?.approvalRequired) {
        this.feeDefinitionsCache.refetch();
        this.depositAccountTypeService.refetch();
        this.redirectBack();
      }
    }
  }

  transactionLimitAvailable(): boolean {
    return this.depositAccountType?.productDefinition.allowedAgentTransactions?.some(
      value => [AgentTransaction.AGENT_ACCOUNT_CASH_DEPOSIT, AgentTransaction.AGENT_ACCOUNT_CASH_WITHDRAWAL].includes(value)
    );
  }

  redirectBack(): void {
    this.$location.path(`/admin/deposit-accounts/types`);
  }

  async cancelChanges(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const confirmed = await this.confirmation('Do you want to cancel? Canceling will discard all changes.');
    if (confirmed) {
      this.redirectBack();
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  setGlMappingComponent(instance): void {
    this.glMappingComponent = instance;
  }
}

nxModule.component('depositAccountDetails', {
  templateUrl,
  controller: DepositAccountDetails,
  bindings: {
    productId: '<'
  }
});
