const templateUrl = require('./loan-pre-terminate-by-cash.template.html');

import BigNumber from 'bignumber.js';
import nxModule from 'nxModule';
import _ from 'lodash';

nxModule.component('loanPreTerminateByCash', {
  templateUrl: templateUrl,
  controller: function ($scope, $location, $route, $filter, customerCache, notification, command, confirmation, loanPreTerminateService) {
    let that = this;

    that.customerId = $route.current.params['customerId'];
    that.loanId = $route.current.params['loanId'];

    that.paymentRequest = {};
    that.waiveRequest = {};
    that.amortizationIds = [];
    that.maxValues;
    that.loan;
    that.title = $filter('uppercase')($filter('translate')('pre-terminate-by-cash', 'LOAN'));

    let subscribe = customerCache.loans(that.customerId).toObservable().subscribe(loans => {
      that.loan = _.find(loans, (l) => l.id === Number(that.loanId));
      that.amortizationIds = _.map(that.loan.amortizationSchedule.list, a => a.id);
      that.maxValues = {...that.loan.amortizationSchedule.totalBalance};
    });

    that.preTerminate = async () => {
      const info = {
        total: that.paymentRequest.amount,
        totalWaive: that.waiveRequest.waiveParts.reduce((sum, item) => sum.plus(item['waiveAmount'] || 0), new BigNumber(0)).toNumber()
      };

      const message = loanPreTerminateService.getMessage(info);
      const doPreterminate = await confirmation(message);
      if (doPreterminate) {
        const request = {
          waiveRequest: that.waiveRequest,
          loanPaymentRequest: loanPreTerminateService.createPaymentRequest(that.paymentRequest, that.officialReceipt)
        };
        await command.execute('PreterminateLoanByCash', request, {nxLoaderText: 'Preterminating loan...'}).toPromise();
        customerCache.loans(that.customerId).refetch();
        customerCache.depositAccounts(that.customerId).refetch();
        that.redirectBack();
      }
    };

    that.redirectBack = () => $location.path(`/customer/${that.customerId}/loans/${that.loanId}`);

    that.$onDestroy = () => {
      subscribe.unsubscribe();
    };
  }
});