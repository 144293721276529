import {ATA_LABELS, ataReplenishStrategiesMap} from 'constants/account';
import _ from 'lodash';

import nxModule from 'nxModule';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import 'rxjs/add/operator/toPromise';
import {Subscription} from 'rxjs/Subscription';
import {enrichOwnersWithRelatives, isCorporate} from '../common/casa-product-util';
import '../common/create-lock/create-lock.component';
import './activate-account/activate-account.component';
import './cash-amount-picker/cash-amount-picker.component';
import './cash-deposit/cash-deposit.component';
import './cash-withdraw/cash-withdraw.component';
import './check-deposit/check-deposit.component';
import './check-deposit/multiple-checks-deposit.component';
import './check-withdrawal/check-withdrawal.component';
import './checkbook/issue/checkbook-issue.component';
import './checkbook/update/checkbook-update.component';
import './close-account/account-forecast.service';
import './close-account/close-account.component';
import './common-operations/account-operation-service';
import './create-hold/create-account-hold.component';
import './credit-on-us-check/credit-on-us-check.component';
import './customer-accounts.style.less';
import './deposit-product-information/deposit-product-information.component';
import './edit-account-signature-card/edit-account-signature-card.component';
import './edit-account/edit-account.component';
import './edit-ata/edit-account-ata.component';
import './edit-collector/edit-collector.component';
import './encash/encash-on-us-check.component';
import './memo/memo.component';
import './new-account/new-account-details.component';
import './new-account/new-account-list.component';
import './pesonet-transfer/pesonet-transfer.component';
import './stop-order/stop-order.component';
import './transfer-to-product/transfer-to-product.component';

const templateUrl = require('./customer-accounts.template.html');
nxModule.component('customerAccounts', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $scope, customerCache, productLockCache, productLockService,
                        productDefinitionService, depositAccountTypeService, branchService, command, $routeParams,
                        modalPrintPreviewService, passbookService, ataCache, userCounterService, transactionDetailsBuilder,
                        operationTableBuilder, authentication, confirmation, fileService, casaTypesCache,
                        revertCommandService, queryParamsRemover, customerService, commandAccessChecker,
                        interestBoardService, dict, accountGroupService, checkbookCache, branchQueryService, appVersionService, $filter,
                        feeDefinitionsCache) {
    const that = this;

    that.profile = {};
    that.depositAccounts = [];
    that.visibleAccounts = [];
    that.showClosed = $routeParams.showClosed === "true";
    that.depositAccountsTransactionHistory = [];
    that.selectedTransactionId = undefined;
    that.authentication = authentication;
    that.permissions = authentication.permissions;
    that.ataReplenishStrategiesMap = ataReplenishStrategiesMap;

    that.keyValueDetails = _.memoize(currentTransaction => {
      let baseValueDetails = transactionDetailsBuilder.build(currentTransaction, 'ACCOUNT');
      baseValueDetails.push(transactionDetailsBuilder.buildCurrencyIfNotZero('Available Balance after', currentTransaction.balanceAfter));

      baseValueDetails = transactionDetailsBuilder.clean(baseValueDetails);

      return baseValueDetails;
    });

    that.ataLabels = ATA_LABELS;

    that.creditOnUsCheckOperationSubgroups = ['CREDIT_CHECK_ON_US', 'OFFSET_CREDIT_CHECK_ON_US'];
    that.keyValueDetails.cache = new WeakMap();

    that.selectedAccount = null;
    that.accountTypes = null;
    that.selectedAccountType = null;

    that.isCheckOnUsOperation = item => {
      if (!item) {
        return false;
      }

      return that.creditOnUsCheckOperationSubgroups.includes(item.operationSubgroup);
    };

    that.isValidationSlipActive = item => {
      if (!item) {
        return false;
      }

      if (that.isCheckOnUsOperation(item)) {
        return that.isCreditOnUsCheckValidationSlipActive;
      }

      return that.isDespositAccountValidationSlipActive;
    };

    that.isPassbookPrintActive = false;
    that.activePassbook = null;
    that.isCheckbookAccount = false;

    const customerId = $route.current.params['customerId'];
    that.accountId = $route.current.params['accountId'];

    that.onlyClosedDeposits = () => {
      return that.depositAccounts && that.depositAccounts.filter(deposit => deposit.status !== 'CLOSED').length === 0;
    };

    queryParamsRemover.removeQueryOnRedirect($scope, ['showClosed']);

    modalPrintPreviewService.canReprint('DEPOSIT_ACCOUNT_VALIDATION_SLIP', (active) => {
      that.isDespositAccountValidationSlipActive = active;
    });

    modalPrintPreviewService.canReprint('ACCOUNT_CREDIT_ON_US_CHECK', (active) => {
      that.isCreditOnUsCheckValidationSlipActive = active;
    });

    modalPrintPreviewService.canReprint('DEPOSIT_ACCOUNT_PASSBOOK', (active) => {
      that.isPassbookPrintActive = active;
    });

    const accountIsLocked = () => {
      return !_.isEmpty(that.nonReleasedLocks);
    };

    that.pendingAccountOperationAllowed = (operation) => {
      return that.selectedAccount.status === 'PENDING' && that.selectedAccount.initialDepositOperations.find(o => o === operation);
    };

    that.showPrint = async (transaction) => {
      if (!transaction) {
        return;
      }

      if (that.isCheckOnUsOperation(transaction)) {
        modalPrintPreviewService.showAsync({
          printDescription: {
            code: 'ACCOUNT_CREDIT_ON_US_CHECK',
            parameters: {
              'OPERATION_SIDE': String(transaction.source.id) === that.accountId ? 'DEBITING' : 'CREDITING',
            }
          },
          printProviderInput: {
            operationId: transaction.id,
            commandId: transaction.commandId
          }
        });

        return;
      }

      await modalPrintPreviewService.showAsync({
        printDescription: {
          code: 'DEPOSIT_ACCOUNT_VALIDATION_SLIP',
          parameters: {
            COMMAND: transaction.commandTypeName
          }
        },
        printProviderInput: {
          operationId: transaction.id
        }
      });
    };

    that.showBatchPrint = (transaction) => {
      const printCode = transaction.operationSubgroup === 'REGULAR' ? 'DEPOSIT_ACCOUNT_BATCH_VALIDATION_SLIP' : 'DEPOSIT_ACCOUNT_BATCH_CREDIT_ONUS_CHECK_VALIDATION_SLIP';
      modalPrintPreviewService.showAsync({
        printDescription: printCode,
        printProviderInput: {
          batchId: transaction.attributes['CHECK_BATCH_UUID'],
          operationId: transaction.id
        }
      });
    };

    that.isValidationSlipSupported = (transaction) => {
      return ['WITHDRAW_CASH',
        'WITHDRAW_CHECK',
        'DEPOSIT_CASH',
        'DEPOSIT_CUTOFF_CHECK',
        'DEPOSIT_CHECK',
        'CREDIT_MEMO',
        'DEBIT_MEMO',
        'TRANSFER_FUNDS',
        'WITHDRAW_ATM'].includes(transaction.operationGroup);
    };

    that.isBatchValidationSlipSupported = (transaction) => {
      return _(transaction.attributes).keys().includes(
        'CHECK_BATCH_UUID'
      );
    };

    that.toggleClosedAccounts = () => {
      if (that.showClosed) {
        that.visibleAccounts = that.depositAccounts;
      } else {
        that.visibleAccounts = that.depositAccounts.filter(deposit => deposit.status !== 'CLOSED');
      }

      if (that.selectedAccount) {
        $location
          .path(`/customer/${customerId}/accounts/${that.selectedAccount.id}`)
          .search('showClosed', that.showClosed.toString());
      }
    };

    that.switchContext = (owner) => {
      let redirectionUrl = `/customer/${owner.customerId}/accounts`;
      $location.path(redirectionUrl);
    };

    that.revertAllowed = (operation, commandName) => {
      return revertCommandService.revertAllowed(operation, commandName);
    };

    const revert = (operation, input, question, revertCommandName) => {
      confirmation(question, () =>
        command.execute(revertCommandName, input).success(() => {
          customerCache.depositAccounts(customerId).refetch();
          customerCache.termDeposits(customerId).refetch();
          customerCache.loans(customerId).refetch();
          userCounterService.refresh();
          checkbookCache.withParam(that.accountId).refetch();
        }));
    };

    that.revertInternalTransfer = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the fund transfer of ${$filter('nxCurrency')(operation.amount)} to ${operation.target.productNumber}?`,
      'AccountTransferToProductRevert'
    );

    that.revertCashDeposit = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the cash deposit of ${$filter('nxCurrency')(operation.amount)}?`,
      'DepositCashToAccountRevert'
    );

    that.revertCheckDeposit = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the check deposit of ${$filter('nxCurrency')(operation.amount)}?`,
      'DepositCheckToAccountRevert'
    );

    that.revertCashWithdrawal = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the cash withdrawal of ${$filter('nxCurrency')(operation.amount)}?`,
      'WithdrawAccountFundsByCashRevert'
    );

    that.revertCheckWithdrawal = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the check withdrawal of ${$filter('nxCurrency')(operation.amount)}?`,
      'WithdrawAccountFundsByCheckRevert'
    );

    that.canRevertInwardCheck = (operation) => {
      const isInwardCheckOperation = (operation.operationGroup === 'WITHDRAW_CHECK' && operation.operationSubgroup === 'CLEAR_INWARD_CHECK')
        || (operation.operationGroup === 'APPLY_FEE' && operation.checkId != null)
        || (operation.operationSubgroup === 'MARK_CLEAR_INWARD_CHECK_ERROR' && operation.checkId != null)
      const isLastCheckOperation = operation.attributes?.LAST_CHECK_CLEARING_OPERATION?.toLowerCase() === 'true';

      return isInwardCheckOperation && isLastCheckOperation;
    };

    that.revertInwardCheck = (operation) => revert(
      operation,
      {
        checkId: operation.checkId
      },
      `Do you want to revert the inward check? (Number: ${operation.attributes['CHECK_NO']}, Amount: ${$filter('nxCurrency')(operation.amount)}).
      The check can be reused afterwards.`,
      'CancelInwardCheck'
    );

    that.revertCreditMemo = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert credit memo of ${$filter('nxCurrency')(operation.amount)}?`,
      'DepositMemoToAccountRevert'
    );

    that.revertDebitMemo = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert debit memo of ${$filter('nxCurrency')(operation.amount)}?`,
      'WithdrawAccountFundsByMemoRevert'
    );

    that.revertCreditOnUsCheckEnabled = (operation) => {
      return operation
        && operation.commandId
        && operation.checkId
        && operation.status !== 'REVERTED'
        && operation.operationGroup === 'TRANSFER_FUNDS'
        && operation.operationSubgroup
        && _.includes(['CREDIT_CHECK_ON_US', 'OFFSET_CREDIT_CHECK_ON_US'], operation.operationSubgroup)
        && (
          _.includes(['TRANSFER_FUNDS_CREDIT_CHECK_ON_US_ACCOUNT_SOURCE', 'TRANSFER_FUNDS_OFFSET_CREDIT_CHECK_ON_US_ACCOUNT', 'TRANSFER_FUNDS_CREDIT_CHECK_ON_US_ACCOUNT_INTERBRANCH_SOURCE'], operation.sourceCode) &&
          _.includes(['TRANSFER_FUNDS_CREDIT_CHECK_ON_US_ACCOUNT_TARGET', 'TRANSFER_FUNDS_OFFSET_CREDIT_CHECK_ON_US_ACCOUNT', 'TRANSFER_FUNDS_CREDIT_CHECK_ON_US_ACCOUNT_INTERBRANCH_TARGET'], operation.targetCode)
        );
    };

    that.revertCreditOnUsCheck = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the credit on-us check of ${$filter('nxCurrency')(operation.amount)}?`,
      'CreditOnUsCheckRevert'
    );

    that.revertEncashOnUsCheck = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the encash on-us check of ${$filter('nxCurrency')(operation.amount)}?`,
      'EncashOnUsCheckRevert'
    );

    that.revertPesonetTransfer = (operation) => revert(
      operation,
      {
        operationId: operation.id,
        commandId: operation.commandId
      },
      `Do you want to revert the outgoing PESONet transfer of ${$filter('nxCurrency')(operation.amount)}?`,
      'PesonetOverTheCounterTransferRevert'
    );

    that.returnCheck = (operation) => {
      const input = {'id': operation.checkId};
      confirmation('Do you want to return the check?', () =>
        command.execute('ReturnAccountCheck', input).success(() => {
          customerCache.depositAccounts(customerId).refetch();
        }));
    };

    that.selectAccount = async (account) => {
      $location.path(`/customer/${customerId}/accounts/${account.id}`)
    };

    that.commandAccessContext = _.memoize(acc => {
      if (!acc) {
        return null;
      }

      return {
        branchId: acc.branchId,
        productStatus: acc.status
      };
    });

    /**
     * Account can be closed if and only if:
     * 1. Holds balance is equal to 0 (no pending operations)
     * 2. Closing fee is equal to account balance
     * 3. Product is: ACTIVE
     * 4. Product is: INACTIVE and balance is zero
     */
    that.closingAvailable = () => {
      let account = that.selectedAccount;
      // If holds balance is > 0 -> leave immediately
      if (account.holdsBalance !== 0) return false;
      // Otherwise check balance against fee
      return !accountIsLocked() && (that.selectedAccount.status === 'ACTIVE' || ['INACTIVE', 'PENDING'].includes(that.selectedAccount.status) && that.selectedAccount.balance === 0);
    };

    that.editAvailable = () => !['INACTIVE', 'CLOSED'].includes(that.selectedAccount.status)
      && !accountIsLocked();

    const ataCreditCommandAllowed = (command) => !that.ata
      || !that.ata.enabled
      || (that.ata.creditCommands && _.includes(that.ata.creditCommands, command));

    const passbookSupported = (accountType) => accountType ? accountType.passbookConfig !== 'PASSBOOK_NOT_SUPPORTED' : false;

    /**
     * Cash deposit can be performed when:
     * 1. Product is: ACTIVE
     * 2. ATA is disabled OR ATA allows cash deposit
     */
    that.cashDepositAvailable = () => (that.selectedAccount.status === 'ACTIVE' || that.pendingAccountOperationAllowed('DepositCashToAccount'))
      && !accountIsLocked()
      && ataCreditCommandAllowed('DepositCashToAccount');

    /**
     * Check deposit can be performed when:
     * 1. Product is: ACTIVE
     * 2. ATA is disabled OR ATA allows check deposit
     * 3. Product is PENDING, requires initial deposit and DEPOSIT_CHECK is registered as an account activation
     * operation
     */
    that.checkDepositAvailable = () => (that.selectedAccount.status === 'ACTIVE' || that.pendingAccountOperationAllowed('DepositCheckToAccount'))
      && !accountIsLocked()
      && ataCreditCommandAllowed('DepositCheckToAccount');

    /**
     * Credit memo operation can be performed when:
     * 1. Product is: ACTIVE
     * 2. ATA is disabled OR ATA allows check deposit
     * 3. Product is PENDING, requires initial deposit and CREDIT_MEMO is registered as an account activation operation
     */
    that.creditMemoAvailable = () => (that.selectedAccount.status === 'ACTIVE' || that.pendingAccountOperationAllowed('DepositMemoToAccount'))
      && !accountIsLocked()
      && ataCreditCommandAllowed('DepositMemoToAccount');

    /**
     * Cash withdrawal can be performed when:
     * 1. Product balance is > 0
     * 2. Product is ACTIVE
     * 3. Account subtype != CHECKING
     */
    that.cashWithdrawalAvailable = () => that.selectedAccount.status === 'ACTIVE'
      && !accountIsLocked()
      && that.selectedAccount.balance > 0
      && that.subtype !== 'CHECKING';

    /**
     * Check withdrawal can be performed when:
     * 1. Product balance is > 0
     * 2. Product is ACTIVE
     * 3. Account subtype != CHECKING
     */
    that.checkWithdrawalAvailable = () => that.selectedAccount.status === 'ACTIVE'
      && !accountIsLocked()
      && that.selectedAccount.balance > 0
      && that.subtype !== 'CHECKING';

    /**
     * On-us check encashment can be performed when:
     * 1. Product is ACTIVE
     * 2. Account subtype != CHECKING
     */
    that.encashOnUsCheckAvailable = () => that.selectedAccount.status === 'ACTIVE'
      && !accountIsLocked()
      && that.subtype !== 'CHECKING';

    /**
     * Debit memo operation can be performed when:
     * 1. Product balance is > 0
     * 2. Product is: ACTIVE
     */
    that.debitMemoAvailable = () => that.selectedAccount.status === 'ACTIVE'
      && !accountIsLocked()
      && that.selectedAccount.balance > 0;

    /**
     * Account transfer to product can be performed when:
     * 1. Product is ACTIVE
     * 2. Product is not locked
     * 3. Product balance is > 0
     */
    that.accountTransferToProductAvailable = () => that.selectedAccount.status === 'ACTIVE'
      && !accountIsLocked()
      && that.selectedAccount.balance > 0;

    that.externalTransferAvailable = () => that.selectedAccount.status === 'ACTIVE'
      && !accountIsLocked()
      && that.selectedAccount.balance > 0;

    /**
     * Create hold operation can be performed when:
     * 1. Product balance is > 0
     * 2. Product is: ACTIVE
     */
    that.createHoldAvailable = () => that.selectedAccount.status === 'ACTIVE'
      && !accountIsLocked()
      && that.selectedAccount.balance > 0;

    /**
     * Activation should be available for INACTIVE
     * products only
     */
    that.activationAvailable = () => that.selectedAccount.status === 'INACTIVE'
      && !accountIsLocked();

    /**
     * Credit on-us check can be performed when:
     * 1. Product is ACTIVE
     * 2. Product is PENDING and allowed to be used on initial operation
     */
    that.creditOnUsCheckAvailable = () => (that.selectedAccount.status === 'ACTIVE' || that.pendingAccountOperationAllowed('CreditOnUsCheck') || that.pendingAccountOperationAllowed('BatchCreditOnUsCheck'))
      && !accountIsLocked();

    /**
     * Activation should be available for CHECKING/COMBO accounts
     */
    that.createStopOrderAvailable = () => that.isCheckbookAccount
      && !accountIsLocked()
      && !['INACTIVE', 'CLOSED'].includes(that.selectedAccount.status);

    /**
     * Checkbook issue action is available only for ACTIVE CHECKING/COMBO accounts
     */
    that.checkbookIssueAvailable = () => that.isCheckbookAccount
      && ['ACTIVE'].includes(that.selectedAccount.status)
      && !accountIsLocked();

    /**
     * Pasbbok issue action is available only for ACTIVE accounts with passbook enabled
     */
    that.passbookIssueAvailable = () => passbookSupported(that.selectedAccount)
      && ['ACTIVE'].includes(that.selectedAccount.status)
      && !accountIsLocked();

    /**
     * Passbook issue action is available only for ACTIVE accounts with passbook enabled
     */
    that.hasActivePassbook = () => that.selectedAccount
      && ['ACTIVE', 'CLOSED'].includes(that.selectedAccount.status)
      && passbookSupported(that.selectedAccount)
      && that.activePassbook;

    const confirmationIfPassbookRequired = (action) => {
      if (that.selectedAccount.passbookConfig === 'PASSBOOK_REQUIRED') {
        confirmation('Is passbook available?', action);
      } else {
        action();
      }
    };

    that.createLockAvailable = () => {
      return !accountIsLocked() && !['INACTIVE', 'CLOSED'].includes(that.selectedAccount.status);
    };

    that.releaseLockAvailable = () => {
      return accountIsLocked();
    };

    that.releaseLock = () => {
      productLockService.releaseProductLocks(that.selectedAccount, null, () => {
        customerCache.depositAccounts(customerId).refetch();
        $route.reload();
      })
    };

    that.canChangeAccountType = () => {
      return !['CONTRACTUAL_SAVINGS_CBU', 'CONTRACTUAL_SAVINGS_PF', 'CONTRACTUAL_SAVINGS_TP'].includes(that.selectedAccountType.purpose);
    };

    that.cashDeposit = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/cash-deposit`);
    that.checkDeposit = async () => {
      let currentBranch = await branchQueryService.getBranchById(that.authentication.context.branchId);
      if (!currentBranch.afterCutoff || await confirmation(`Branch is already past check cutoff. Do you wish to proceed?`)) {
        $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/check-deposit`);
      }
    };
    that.multipleChecksDeposit = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/multiple-checks-deposit`);
    that.creditMemo = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/credit-memo`);
    that.internalTransfer = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/internal-transfer`);
    that.cashWithdraw = () => confirmationIfPassbookRequired(() => {
      $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/cash-withdraw`);
    });
    that.checkWithdrawal = () => confirmationIfPassbookRequired(() => {
      $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/check-withdrawal`);
    });
    that.encashCheck = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/encash-check`);
    that.debitMemo = () => confirmationIfPassbookRequired(() => {
      $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/debit-memo`);
    });
    that.transferToProduct = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/transfer-to-product`);
    that.pesonetTransfer = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/pesonet-transfer`);
    that.instapayTransfer = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/instapay-transfer`);
    that.createHold = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/create-hold`);
    that.createLock = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/create-lock`);
    that.activateAccount = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/activate-account`);
    that.closeZeroBalance = () => confirmationIfPassbookRequired(() => {
      $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/close/zero-balance`);
    });
    that.closeAccountCash = () => confirmationIfPassbookRequired(() => {
      $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/close/cash`);
    });
    that.closeAccountCheck = () => confirmationIfPassbookRequired(() => {
      $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/close/check`);
    });
    that.closeAccountCheckEncash = () => confirmationIfPassbookRequired(() => {
      $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/close/check-encashment`);
    });
    that.reopenAccount = () =>
      confirmation('Do you want to reopen selected account?', () =>
        command.execute('ReopenAccount', {productId: that.selectedAccount.id})
          .success(() => customerCache.depositAccounts(customerId).refetch())
      );
    that.createNew = () => $location.path(`/customer/${customerId}/accounts/create`);
    that.createStopOrder = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/stop-order`);
    that.creditOnUsCheck = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/credit-on-us-check`);
    that.batchCreditOnUsChecks = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/batch-credit-on-us-checks`);
    that.edit = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/edit`);
    that.editSignatureCard = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/edit-signature-card`);
    that.changeAccountType = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/change-account-type`);
    that.createAlias = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/create-alias`);
    that.editCollector = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/edit-collector`);
    that.editAta = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/edit-ata`);
    that.issueCheckbook = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/issue-checkbook`);
    that.issuePassbook = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/issue-passbook`);
    that.printDetails = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/details`);
    that.configAda = () => $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/config-ada`);

    that.s1 = customerCache.profile(customerId, true, authentication.permissions['CST_CLOSED_CIF_READ']).toObservable()
      .combineLatest(customerCache.depositAccounts(customerId, false).toObservable(), (profile, depositAccount) => {
        customerService.redirectWhenProfileIsInvalid(profile);

        if (profile.status === 'CLOSED') {
          return [];
        }

        that.profile = profile;
        dict.onLoadingComplete(() => {
          that.profile.relatedCustomers.forEach(relative => {
            relative.relation = dict.getDescription('RELATIVE_TYPE', relative.typeId);
          });
          that.profile.relatedNonCustomers.forEach(relative => {
            relative.effectiveName = `${relative.lastName}, ${relative.firstName}`;
            relative.relation = dict.getDescription('RELATIVE_TYPE', relative.typeId);
          });

          that.profile.relatives = [...that.profile.relatedCustomers, ...that.profile.relatedNonCustomers];
          that.sssPensionTargetAccountIds = that.profile.incomeSources.filter(i => i.type === 'SSS').map(i => i.sssPensionTargetAccountId);
        });
        return depositAccount
      })
      .combineLatest(productDefinitionService.toObservable(), (depositAccounts, products) =>
        depositAccounts.map(da => {
          const p = _.find(products, {id: da.definitionId});
          return Object.assign(da, {
            productName: p ? p.productName : '<Unknown product>',
            closingFee: p ? p.closingFee : null
          });
        })
      )
      .combineLatest(depositAccountTypeService.toObservable(), (depositAccounts, accountTypes) => {
        that.accountTypes = accountTypes;
        return depositAccounts.map(a => {
          const p = _.find(accountTypes, {id: a.typeId});
          return Object.assign(a, {
            closingFee: p ? p.closingFee : null,
            accountSubtype: p ? p.accountSubtype : null,
            passbookConfig: p ? p.passbookConfig : undefined,
            subtype: p ? p.accountSubtype : 'SAVINGS',
            initialDepositRequired: p.initialDepositRequired,
            initialDepositOperations: p.initialDepositOperations,
            minInitialDepositAmount: p.minInitialDepositAmount,
            fullInitialDepositRequired: p.fullInitialDepositRequired,
            interestRange: a.interestRate ? a.interestRate : interestBoardService.formatInterestRateRangeForRateType(p.interestBoards),
            supportsGroup: p ? p.supportsGroup : false,
            type: p,
          });
        })
      }).combineLatest(feeDefinitionsCache.toObservable(), (depositAccounts, feeDefinitions) =>
        depositAccounts.map(a => {
          const withholdingTaxDef = _.filter(feeDefinitions, {productDefinitionId: a.definitionId})
            .find(fd => fd.feeClass === 'WITHHOLDING_TAX');

          return Object.assign(a, {
            withholdingTax: withholdingTaxDef?.percentageAmount,
          });
        })
      )
      // enrich with branch name from branch service
      .combineLatest(branchService.toObservable(), (depositAccounts, branches) =>
        depositAccounts.map(da => {
          const branch = _.find(branches, {
            id: da.branchId
          });
          return Object.assign(da, {
            branchName: branch ? branch.name : '<Unknown>'
          });
        })
      )
      // enrich with pdic casa types
      .combineLatest(casaTypesCache.toObservable(), (depositAccounts, casaTypes) => {
        that.pdicCasaTypes = _.filter(casaTypes, {'regulatorType': 'PDIC'});
        return depositAccounts.map(da => {
          const pdicType = _.find(that.pdicCasaTypes, {id: da.pdicTypeId});
          const name = pdicType != null ? pdicType.name : null;
          return Object.assign(da, {
            pdicTypeName: name != null ? name.substring(name.indexOf('/') + 1) : null
          });
        })
      })
      .combineLatest(customerCache.depositAccountGroups(customerId).toObservable(), (depositAccounts, accountGroups) => {
        if (accountGroups) {
          depositAccounts = depositAccounts.map(da => {
            const group = accountGroups.find(g => g.id === da.groupId);
            return {
              ...da,
              group: group
            };
          });
        }
        return depositAccounts;
      })
      .subscribe(accounts => {
          // select first account when none selected
          if (!that.accountId && accounts.length > 0) $location.path(`/customer/${customerId}/accounts/${accounts[0].id}`);

          if (that.accountId) {
            const account = _.find(accounts, (a) => a.id === Number(that.accountId));
            if (account) {
              that.selectedAccount = account;
              that.selectedAccountType = _.find(that.accountTypes, {id: that.selectedAccount.typeId});
              that.isCheckbookAccount = ['CHECKING', 'COMBO'].includes(account.accountSubtype);
              that.isCorporate = isCorporate(that.profile, that.selectedAccount, that.pdicCasaTypes);
              if (that.isCorporate) {
                enrichOwnersWithRelatives(that.selectedAccount, that.profile.relatedCustomers);
              }
            } else {
              $location.path(`/customer/${customerId}/accounts`);
            }
          }

          that.depositAccounts = accounts;

          if (that.showClosed) {
            that.visibleAccounts = accounts;
          } else {
            that.visibleAccounts = accounts.filter(account => account.status !== 'CLOSED');

            if (that.selectedAccount && that.selectedAccount.status === 'CLOSED' && that.selectAccount && that.visibleAccounts[0]) {
              that.selectAccount(that.visibleAccounts[0]);
            } else if (that.selectedAccount && that.selectedAccount.status === 'CLOSED' && that.onlyClosedDeposits()) {
              that.showClosed = true;
              that.toggleClosedAccounts();
            }
          }

          return accounts;
        }
      );

    that.subscription = new Subscription();
    that.subscription.add(that.s1);

    if (that.accountId) {
      // Add passbook subscription
      passbookService.readByProductId(that.accountId)
        .then(data => {
        that.activePassbook = data;
        that.activePassbook.nextLine = data.lastUsedLine + 1;
      });
      // Add ATA subscription
      that.subscription.add(ataCache.withParam(that.accountId).toObservable().subscribe(ata => that.ata = ata));
      // Add product locks subscription
      that.subscription.add(productLockCache.withParam(that.accountId).toObservable().subscribe(locks => {
        that.locks = locks;
        that.nonReleasedLocks = _.filter(locks || [], {released: false});
      }));
    }

    that.addToGroup = () => {
      $location.path(`/customer/${customerId}/accounts/${that.selectedAccount.id}/group`);
    };

    that.removeFromGroup = async () => {
      const proceed = await confirmation(`Account ${that.selectedAccount.productNumber} will be removed from group ${that.selectedAccount.groupId}. Do you wish to proceed?`);
      if (proceed) {
        const request = {
          accountId: that.selectedAccount.id
        };
        await command.execute('RemoveAccountGroupMember', request).toPromise();
        customerCache.depositAccounts(customerId).refetch();
        customerCache.depositAccountGroups(customerId).refetch();
      }
    };

    that.hasReturnCheckOperation = operation => {
      return 'RETURN_CHECK_OPERATION_ID' in operation.attributes;
    };

    that.onAccountGroupModalApiReady = ({api,}) => {
      that.modalApi = api;
    };

    that.showGroupDetails = async () => {
      that.selectedAccountGroup = await accountGroupService.getCompleteAccountGroupDetails(that.selectedAccount.group);
      await that.modalApi.show();
    };

    that.canEditCheckNumber = (item) => {
      if (that.selectedAccount.status === 'CLOSED') {
        return false;
      }

      if (item.commandTypeName === 'CreditOnUsCheck') {
        return item.source.id === that.selectedAccount.id;
      }

      return [
        'ClearInwardCheck',
        'EncashOnUsCheck',
        'PayLoanByOnUsCheck'
      ].includes(item.commandTypeName);
    }

    that.editCheckNumber = (item) => {
      if (item.checkId) {
        $location.path(`/check/${item.checkId}/update-number`);
      }
    }

    that.formatProductAndExtraNumber = (item) =>
      (item.extraNumber === item.productNumber || !item.extraNumber) ? item.productNumber :
        `${item.productNumber} (${item.extraNumber})`;

    that.$onDestroy = () => that.subscription.unsubscribe();
  }
});
