import {CustomFieldGroup} from 'custom-field/CustomFieldDefinitionTypes';
import {allRoutes} from 'custom-field/CustomFieldRoutes';
import React, {ReactElement} from 'react';
import ReportColumnConfigurationView from 'report/ReportColumnConfigurationView';
import ReportColumnConfigurationContainer from 'report/ReportConfiguationContainer';
import ReportConfigurationList from 'report/ReportConfigurationList';
import ReportOrderConfigurationView from 'report/ReportOrderConfigurationView';
import ReportService from 'report/ReportService';
import RouteDefinition from 'routes/RouteDefinition';

const reportService = new ReportService();
const ReportColumnView = () : ReactElement => <ReportColumnConfigurationContainer viewType={ReportColumnConfigurationView}/>;
const ReportOrderView = () : ReactElement  => <ReportColumnConfigurationContainer viewType={ReportOrderConfigurationView}/>;

const routes: RouteDefinition[] = [
  {
      name: 'Report configuration',
      path: '/admin/report-configuration',
      commandAccess: 'UpdateReportConfiguration',
      Component: ReportConfigurationList
  },
  {
    name: async (params: {reportCode: string}): Promise<string> => {
      const descriptor = await reportService.readDescriptor({reportCode: params.reportCode});
      return descriptor.name;
    },
    path: '/admin/report-configuration/:reportCode',
    commandAccess: 'UpdateReportConfiguration',
  },
  {
      name: 'Columns',
      path: '/admin/report-configuration/:reportCode/columns',
      commandAccess: 'UpdateReportConfiguration',
      Component: ReportColumnView
  },
  {
      name: 'Order',
      path: '/admin/report-configuration/:reportCode/order',
      commandAccess: 'UpdateReportConfiguration',
      Component: ReportOrderView
  },
  ...allRoutes('/admin/customer', CustomFieldGroup.CUSTOMER, true)
];

export default routes;
