import './collections-payment.style.less'
import templateUrl from './collections-payment.template.html';
import {HttpService} from "shared/utils/httpService";
import {NgTableParams} from "ng-table";
import {PageResult} from "tools/HttpTypes";
import {ModalApi} from "components/technical/modal/modal.component";
import {NxIFilterService} from "components/technical/angular-filters";
import systemPropertyService from "system/systemPropertyService";
import {CollectionReceiptType} from "components/administration/global-settings/global-settings.component";
import Authentication from "shared/utils/authentication";
import nxModule from "nxModule";
import {
  GroupFilter,
  IndividualFilter,
  SearchBy, SortBy
} from "components/dashboard/collections/filter/collections-filter.component";
import {IScope} from "angular";
import {Confirmation} from "shared/common/confirmation.types";
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";
import Popup from "shared/common/popup";

export interface CollectionsMember {
  productId: number;
  productNumber: string;
  customerName: string;
  productName: string;
  principalAmount: string;
  principalBalance: string;
  earliestDueDate: string;
  latestDueDate: string;
  cbuChargeBalance: number;
  dueAmount: number;
  overdueAmount: number;
  outstandingAmount: number;
  status: 'UNPAID' | 'DUE' | 'OVERDUE' | 'PAID';
}

export interface CollectionsGroup {
  groupCustomerId: number;
  groupName: string;
  groupAddress: string;
  collectionDay: string;
  collectionTimeFrom: string;
  collectionTimeTo: string;
  latestDueDate: string;
  totalPrincipalAmount: number;
  totalPrincipalBalance: number;
  totalDueAmount: number;
  totalOverdueAmount: number;
  totalOutstandingAmount: number;
}

interface Payment {
  selected: boolean;
  amount: number;
  collection: CollectionsMember
  groupName?: string;
  temporaryReceipt?: string;
  officialReceipt?: string;
}

interface PaymentRequest {
  productId: number,
  amount: number,
  commandPurpose: string,
  valueDate?: string,
  temporaryReceipt?: string,
  officialReceipt?: string
}

interface PaymentResponse {
  valid: boolean;
  operationId?: number;
  productNumber: string;
  paymentAmount: number;
  errorMessage?: string;
}

class CollectionsPayment {
  // Tables
  private showTable!: boolean;
  private individualTableConfig!: NgTableParams<CollectionsMember>;
  private groupTableConfig!: NgTableParams<CollectionsGroup>;
  private groupMembersTableConfig?: NgTableParams<CollectionsMember>;

  // Modal
  private membersModal!: ModalApi;
  private selectedGroup?: CollectionsGroup;
  private selectedPageForPayment!: boolean;
  private groupMembersSortBy!: SortBy;

  // Binding
  private searchBy!: SearchBy;
  private individualFilter!: IndividualFilter;
  private groupFilter!: GroupFilter;

  // Payment
  private payments!: { [productId: number]: Payment }
  private noOfficialReceiptAvailable!: boolean;
  private valueDate?: string;

  // System properties
  private valueDateEnabled!: boolean;
  private automatedOfficialReceiptEnabled!: boolean;
  private collectionModuleReceiptRequired!: boolean;
  private collectionsReceiptType!: CollectionReceiptType;

  constructor(
    private authentication: Authentication,
    private http: HttpService,
    private confirmation: Confirmation,
    private command: CommandService,
    private $filter: NxIFilterService,
    private customerCache: CustomerCache,
    private $scope: IScope,
    private popup: Popup,
  ) {}

  $onInit(): void {
    this.showTable = false;
    this.payments = {};
    this.collectionsReceiptType = <CollectionReceiptType> systemPropertyService.getPropertyOrError('COLLECTIONS_RECEIPT_TYPE');
    this.valueDateEnabled = systemPropertyService.getProperty('LOAN_PAYMENT_VALUE_DATE_ENABLED') === 'TRUE';
    this.automatedOfficialReceiptEnabled = systemPropertyService.getProperty('AUTOMATED_OFFICIAL_RECEIPTS_ENABLED') === 'TRUE';
    this.collectionModuleReceiptRequired = systemPropertyService.getProperty('COLLECTIONS_MODULE_RECEIPT_REQUIRED') === 'TRUE';
    this.noOfficialReceiptAvailable = false;
    this.valueDate = undefined;
    this.selectedPageForPayment = false;
    this.groupMembersSortBy = 'CUSTOMER_NAME';

    // Clear tables when the filters changed
    this.$scope.$watch('$ctrl.individualFilter', () => {
      this.showTable = false;
      this.clearPayments();
    }, true);
    this.$scope.$watch('$ctrl.groupFilter', () => {
      this.showTable = false;
      this.clearPayments();
    }, true);
    this.$scope.$watch('$ctrl.searchBy', () => {
      this.showTable = false;
      this.clearPayments();
    }, true);
  }


  // TABLE METHODS
  prepareIndividualTable(): NgTableParams<CollectionsMember> {
    return new NgTableParams({
      page: 1,
      count: 20
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: async (params: NgTableParams<CollectionsMember>): Promise<CollectionsMember[]> => {
        const requestParams = {
          ...this.individualFilter,
          pageNo: params.page() - 1,
          pageSize: params.count()
        }
        const response = await this.http.post<PageResult<CollectionsMember>>(
          '/products/loans/collections/members',
          requestParams
        ).toPromise();

        params.total(response.totalCount);
        this.clearPayments();
        return response.result;
      }
    });
  }

  prepareGroupTable(): NgTableParams<CollectionsGroup> {
    return new NgTableParams({
      page: 1,
      count: 20
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: async (params: NgTableParams<CollectionsGroup>): Promise<CollectionsGroup[]> => {
        const requestParams = {
          ...this.groupFilter,
          pageNo: params.page() - 1,
          pageSize: params.count()
        }
        const response = await this.http.post<PageResult<CollectionsGroup>>(
          '/products/loans/collections/groups',
          requestParams
        ).toPromise();

        params.total(response.totalCount);
        this.clearPayments();
        return response.result;
      }
    });
  }

  prepareGroupMembersTable(group: CollectionsGroup): NgTableParams<CollectionsMember> {
    return new NgTableParams({
      page: 1,
      count: 100
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: async (params: NgTableParams<CollectionsMember>): Promise<CollectionsMember[]> => {
        const requestParams = {
          dateTo: this.groupFilter.dateTo,
          branchId: this.groupFilter.branchId,
          groupCustomerId: group.groupCustomerId,
          sortBy: this.groupMembersSortBy,
          pageNo: params.page() - 1,
          pageSize: params.count()
        }
        const response = await this.http.post<PageResult<CollectionsMember>>(
          '/products/loans/collections/members',
          requestParams
        ).toPromise();

        this.selectedPageForPayment = false;
        params.total(response.totalCount);
        this.clearPayments();
        return response.result;
      }
    });
  }

  getRowNo(index: number, tableConfig: NgTableParams<CollectionsMember>): number {
    const currentPage = tableConfig.page() - 1;
    const rowsPerPage = tableConfig.count();
    return (currentPage * rowsPerPage) + index + 1
  }

  async generate(): Promise<void> {
    this.showTable = true;
    this.valueDate = undefined;
    this.clearPayments();

    if (this.searchBy === 'INDIVIDUAL') {
      this.individualTableConfig = this.prepareIndividualTable();
    } else {
      this.groupTableConfig = this.prepareGroupTable();
    }
  }

  hasValidFilter(): boolean {
    const filter = this.searchBy === 'INDIVIDUAL'
      ? this.individualFilter
      : this.groupFilter;

    if (!filter) {
      return false;
    }

    return !!filter.dateTo && !!filter.branchId && !!filter.loanTypeIds;
  }


  // MODAL METHODS
  async showGroupMembersModal(group: CollectionsGroup): Promise<void> {
    this.selectedGroup = group;
    this.groupMembersTableConfig = this.prepareGroupMembersTable(group);
    await this.membersModal.show();
  }


  // PAYMENT METHODS
  async toggleForPayment(collection: CollectionsMember): Promise<void> {
    const productId = collection.productId;

    if (!this.getPayment(productId)) {
      this.addForPayment(collection);
    } else {
      this.removeForPayment(collection);
    }

    await this.assignOfficialReceipts();
  }

  addForPayment(collection: CollectionsMember): void {
    this.payments[collection.productId] = {
      selected: true,
      amount: collection.outstandingAmount,
      groupName: this.selectedGroup?.groupName,
      collection: collection
    }
  }

  removeForPayment(collection: CollectionsMember): void {
    delete this.payments[collection.productId];
  }

  async togglePageForPayment(collections: CollectionsMember[]): Promise<void> {
    if (this.selectedPageForPayment) {
      for (const collection of collections) {
        if (collection.outstandingAmount > 0) {
          this.addForPayment(collection);
        }
      }
    } else {
      for (const collection of collections) {
        this.removeForPayment(collection);
      }
    }

    await this.assignOfficialReceipts();
  }

  async assignOfficialReceipts(): Promise<void> {
    if (!this.automatedOfficialReceiptEnabled) {
      return;
    }

    const payments = this.getPayments();
    if (payments.length > 0) {
      const url = `/official-receipt/next-n-available?userId=${this.authentication.context.id}&count=${payments.length}`;
      const response = <string[]> await this.http.get(url).toPromise();
      const officialReceipts = response.reverse();

      if (officialReceipts.length !== payments.length) {
        this.noOfficialReceiptAvailable = true;
      }

      for (const payment of payments) {
        if (officialReceipts.length > 0) {
          payment.officialReceipt = officialReceipts.pop();
        } else {
          break;
        }
      }
    }
  }

  getPayments(): Payment[] {
    const filter = this.searchBy === 'INDIVIDUAL'
      ? this.individualFilter
      : this.groupFilter;

    return Object.values(this.payments ?? {}).sort((p1: Payment, p2: Payment) => {
      switch (filter.sortBy) {
        case "CUSTOMER_NAME":
          return p1.collection.customerName.localeCompare(p2.collection.customerName);
        case "DUE_DATE":
          return Date.parse(p1.collection.latestDueDate) - Date.parse(p2.collection.latestDueDate);
        case "DUE_DATE_DESC":
          return Date.parse(p2.collection.latestDueDate) - Date.parse(p1.collection.latestDueDate);
        default:
          throw new Error(`Unknown sortBy: ${filter.sortBy}`);
      }
    });
  }

  getPaymentsTotalAmount(): number {
    return this.getPayments()
      .map(p => p.amount)
      .reduce((a1:number , a2: number) => a1 + a2, 0);
  }

  getPayment(productId: number): Payment | undefined {
    if (this.payments[productId] != undefined) {
      return this.payments[productId];
    }
  }

  clearPayments(): void {
    this.payments = {};
    this.noOfficialReceiptAvailable = false;
    this.selectedPageForPayment = false;
  }

  isValidPayments(): boolean {
    const payments = this.getPayments();

    if (payments.length === 0) {
      return false;
    }

    if (this.collectionModuleReceiptRequired && this.collectionsReceiptType === 'OFFICIAL') {
      if (payments.filter(p => !p.officialReceipt).length > 0) {
        return false;
      }
    }

    if (this.collectionModuleReceiptRequired && this.collectionsReceiptType === 'TEMPORARY') {
      if (payments.filter(p => !p.temporaryReceipt).length > 0) {
        return false;
      }
    }

    return true;
  }

  showFailedPayments(failedPayments: PaymentResponse[]): void {
    let errorMsg: string = '';
    for (const failedPayment of failedPayments) {
      errorMsg += `Product number: ${failedPayment.productNumber}<br/>Amount: ${this.$filter('nxCurrency')(failedPayment.paymentAmount)}<br/>Error: ${failedPayment.errorMessage}<br/><br/>`;
    }
    this.popup({ header: 'Some payments have failed', text: errorMsg, renderHtml: true });
  }

  async proceedPayment(showConfirmation: boolean): Promise<void> {
    const paymentRequests: PaymentRequest[] = this.getPayments().map(p => {
      return {
        productId: p.collection.productId,
        amount: p.amount,
        commandPurpose: 'LOAN_COLLECTIONS_PAYMENT',
        valueDate: this.valueDate,
        temporaryReceipt: p.temporaryReceipt,
        officialReceipt: p.officialReceipt
      }
    })
    if (showConfirmation) {
      const totalAmount = this.$filter('nxCurrency')(this.getPaymentsTotalAmount());
      if (!await this.confirmation(`Do you want to register ${paymentRequests.length} payment(s) for ${totalAmount} ?`)) {
        return;
      }
    }

    const response = await this.command.execute<{loanPaymentRequests: PaymentRequest[]}, PaymentResponse[]>(
      'PayLoanByCashBatch', { loanPaymentRequests: paymentRequests }
    ).toPromise();

    const failedPayments = response.output.filter(r => !r.valid)
    if (failedPayments.length > 0) {
      this.showFailedPayments(failedPayments);
    }

    if (response.approvalRequired) {
      return;
    }

    await this.generate();
    if (this.customerCache.loadedCustomerId) {
      this.customerCache.loans(this.customerCache.loadedCustomerId).evict();
    }
  }
}

nxModule.component('collectionsPayment', {
  templateUrl,
  bindings: {
    searchBy: '=',
    individualFilter: '=',
    groupFilter: '='
  },
  controller: CollectionsPayment
});
