const templateUrl = require('./loan-pre-terminate-by-transfer.template.html');
import BigNumber from 'bignumber.js';
import nxModule from 'nxModule';
import _ from 'lodash';

nxModule.component('loanPreTerminateByTransfer', {
  templateUrl: templateUrl,
  controller: function ($location, $route, $filter, customerCache, notification, http, command, confirmation, depositAccountTypeService, loanPreTerminateService) {
    let that = this;

    that.accounts = null;
    that.selectedAccount = null;

    that.customerId = $route.current.params['customerId'];
    that.loanId = $route.current.params['loanId'];

    that.paymentRequest = {};
    that.waiveRequest = {};
    that.amortizationIds = [];
    that.title = $filter('uppercase')($filter('translate')('pre-terminate-by-transfer', 'LOAN'));

    that.$onInit = async () => {
      const [accounts, accountTypes, relatedAccounts, loans] = await Promise.all([
        customerCache.depositAccounts(that.customerId).toPromise(),
        depositAccountTypeService.toPromise(),
        customerCache.relatedDepositAccounts(that.customerId).toPromise(),
        customerCache.loans(that.customerId).toPromise()
      ]);

      const allDepositAccounts = [...accounts, ...relatedAccounts];
      that.accounts = allDepositAccounts.filter(account => account.status === 'ACTIVE')
        .map(account => {
          const type = accountTypes.find(type => type.id === account.typeId);
          if (type) {
            account.name = `${type.productDefinition.productName} [${account.productNumber}]`;
          }
          return account;
        });

      that.loan = _.find(loans, (l) => l.id === Number(that.loanId));
      that.amortizationIds = _.map(that.loan.amortizationSchedule.list, a => a.id);
      that.maxValues = {...that.loan.amortizationSchedule.totalBalance};
    };

    that.getRequestBody = () => {
      const paymentRequest = loanPreTerminateService.createPaymentRequest(that.paymentRequest);
      paymentRequest.accountId = that.selectedAccount.id;
      return {
        waiveRequest: that.waiveRequest,
        loanPaymentRequest: paymentRequest
      };
    };

    that.preTerminate = () => {
      var info = {
        total: that.paymentRequest.amount,
        totalWaive: that.waiveRequest.total.totalWaive
      };

      var message = loanPreTerminateService.getMessage(info);
      confirmation(message, () => {
        command.execute('PreterminateLoanByTransfer', that.getRequestBody(), {
            nxLoaderText: 'Performing operation...'
          }).success(() => {
          customerCache.loans(that.customerId).refetch();
          customerCache.depositAccounts(that.customerId).refetch();
          that.redirectBack();
        }).offline(() => {
          customerCache.loans(that.customerId).refetch();
          customerCache.depositAccounts(that.customerId).refetch();
          that.redirectBack();
        })
      });
    };

    that.redirectBack = () => $location.path(`/customer/${that.customerId}/loans/${that.loanId}`);
  }
});